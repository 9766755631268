<template>
  <div class="about container-fluid px-0">
    <WhyCleaner />
    <div class="container py-5">
      <h3>Cleaner</h3>
      <p>
        <strong>CLEANER</strong> е компания с над 13 годишен опит в професионалното и индустриално почистване. <br><br>
        Цялостна поддръжка на административни и обществени сгради, малки и големи офиси, заводи и шоуруми, производствени помещения и търговски центрове.
       <br><br> Ръководим се от три основни принципа - мобилност, гъвкавост и коректност.
       <br><br> Нашата основна цел е максимално удовлетворяване на изискванията на клиентите ни. 
        Към всеки проект подхождаме индивидуално, като предлагаме план за почистване, съобразен с конкретните нужди и изисквания.
        Поддържаме баланс между качество, бързина и стойност на услугите, които предлагаме. <br><br>
        Разполагаме с техника, която задоволява всички нужди на нашите клиенти. Работим изцяло с машини и техника на немската компания "HAKO" и италианската компания "FIMAP"и „GHIBLI”. Ползваме препарати на фирма KITER, които са водеща марка за качество на професионални почистващи препарати.
        <br><br><strong>CLEANER</strong> е компания, която освен услугата професионално почистване, успешно предлага на пазара и дейностите:
       <br><br> <strong>1.ДДД -</strong> <br><br>
        е национален ПЕСТ КОНТРОЛ оператор. ДДД дивизия е наш екип от висококвалифицирани специалисти, притежаващи необходимия опит и стаж по специалността.
        Дейностите на ДДД дивизия са - извършване на дезинфекции, дезинсекции и дератизации на територията на цялата страна. Специализирани сме в обработката на търговски обекти тип МОЛ, супер и хипер маркети, предприятия на ХВП и всякакви други производствени предприятия.
       <br><br> <strong>2.Озеленяване и поддръжка на площи -</strong><br><br>
        Нашите квалифицирани градинари ще се погрижат за Вашия двор и градина, за да може Вие да се насладите на спокойствието и красотата им.
        Предлагаме на нашите клиенти: <br><br>
                  – Проектиране и изграждане на зелени площи; <br><br>
                  – Абонаментна поддръжка на зелени площи/косене на трева, градински услуги, почистване на дворове/. <br><br>
                  – Доставка и поддръжка на интериорни растения за Вашия дом или офис; <br><br>
        <strong>3.Фасилити менидмънт /цялостна поддръжка на сгради/ -</strong> <br><br>
        CLEANER е с дългогодишен опит в управлението и поддръжката на корпоративни и жилищни обекти. Има изградена структура за управление на процесите и екипи за предоставяне на основните услуги почистване, сигурност и охрана, поддръжка на зелени площи и техническа поддръжка на системи, както и изградена мрежа от партньори, с които работи в сфери, в които няма изградени собствени звена като строителство и ремонти, ДДД услуги и др.
      <br><br>  Основната цел на компанията е да предостави цялостно решение за управление на процесите свързани с поддържането и естетическата визия на сградния фонд.
        CLEANER работи с клиенти от корпоративния сектор собственици на офис сгради, производствени и складови бази, заводи, търговски центрове и в жилищния сектор с инвеститори в комплекси от затворен тип.
      </p>
    </div>
  </div>
</template>

<script>
  import WhyCleaner from '../components/includes/WhyCleaner'

  export default {
    name: 'About',
    components: {
      WhyCleaner,
    }
  }
</script>
